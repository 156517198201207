import React, { useState, useEffect, useContext } from 'react';
import AuthContext from './AuthContext';
import '../style/DragDropList.css';
import * as signalR from '@microsoft/signalr';

const apiUrl = process.env.REACT_APP_API_BASE_URL;

export default function DragDropList(props) {
  const [items, setItems] = useState([])
  const [input, setInput] = useState('')
  const { isAuthenticated, isTokenValid, token } = useContext(AuthContext);
  let isConnectedToSignarlRHub = false

  useEffect(() => {
    if (!isAuthenticated) {
      console.log('User not authenticated')
      return
    }
      
    if (!isTokenValid()) {
      console.log('Token has expired')
      return
    }

    // Create connection
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(`${apiUrl}/databaseNotificationHub`, {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
        accessTokenFactory: () => token
      })
      .withAutomaticReconnect()
      .build();
      
      const startConnection = async () => {
        if (!isConnectedToSignarlRHub) {
          try {
            isConnectedToSignarlRHub = true
            await connection.start();
            console.log('Connected to SignalR');
          } catch (error) {
            isConnectedToSignarlRHub = false
            console.error('Error connecting to SignalR Hub:', error);
          }
        }
    };

    startConnection();

    connection.on("ReceiveAllRows", function(data) {
      console.log("Received all rows from server:", data);
      // Handle the data, update UI, etc.
      setItems(data.sort((a, b) => a.order - b.order));
    });

    return () => {
      if (isConnectedToSignarlRHub && connection.state === signalR.HubConnectionState.Connected) {
        connection.stop()
            .then(() => console.log('SignalR connection stopped'))
            .catch(err => console.error('Error stopping connection', err));
        isConnectedToSignarlRHub = false
      }
    };
  }, [isAuthenticated]);
  
  const getNotesURL = `${apiUrl}/api/Storage/GetNotes`
  // Function to fetch notes from the backend using fetch
  const fetchNotes = async () => {
    try {
      console.log('Fetching notes')

      if (!isAuthenticated) {
        console.log('User not authenticated')
        return
      }
        
      if (!isTokenValid()) {
        console.log('Token has expired')
        return
      }

      const response = await fetch(getNotesURL, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      if (!response.ok) {
        throw new Error();
      }
      const data = await response.json();
      setItems(data.sort((a, b) => a.order - b.order));
    } catch (error) {
      console.error("Error fetching notes:", error);
    } 
  };
  
  useEffect(() => {
    if (isAuthenticated && isTokenValid()) {
      fetchNotes()
    }
    else {
      setItems([])
    }
  }, [isAuthenticated])
  
  const updateNoteOrderURL = `${apiUrl}/api/Storage/UpdateNoteOrder`
   // Function to update the order of a note using fetch
  const updateNoteOrder = async (id, newOrder) => {
    try {
      console.log('Updating note')
      
      if (!isAuthenticated) {
        console.log('User not authenticated')
        return
      }
        
      if (!isTokenValid()) {
        console.log('Token has expired')
        props.onShowSessionExpiredPopUp()
        return
      }

      const response = await fetch(updateNoteOrderURL, {
          method: 'PUT',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({
              NoteId: id,
              NewOrder: newOrder
          })
      });
      if (!response.ok) {
          throw new Error();
      }
      // Re-fetch the notes after updating the order
    } catch (error) {
        console.error("Error updating order:", error);
    }
  };

  const deleteNoteOrderURL = `${apiUrl}/api/Storage/DeleteNote`
  // Function to update the order of a note using fetch
  const deleteNoteOrder = async (id) => {
    try {
      console.log('Deleting note')

      if (!isAuthenticated) {
        console.log('User not authenticated')
        return
      }
        
      if (!isTokenValid()) {
        console.log('Token has expired')
        props.onShowSessionExpiredPopUp()
        return
      }

      const response = await fetch(deleteNoteOrderURL, {
          method: 'DELETE',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({
              NoteId: id
          })
      });
      if (!response.ok) {
          throw new Error();
      }
      // Re-fetch the notes after updating the order
    } catch (error) {
        console.error("Error deleting note:", error);
    }
  };

  const AddNoteOrderURL = `${apiUrl}/api/Storage/AddNote`
  // Function to update the order of a note using fetch
  const addNote = async (content, order) => {
    try {
      console.log('Adding note')

      if (!isAuthenticated) {
        console.log('User not authenticated')
        return
      }
        
      if (!isTokenValid()) {
        console.log('Token has expired')
        props.onShowSessionExpiredPopUp()
        return
      }

      const response = await fetch(AddNoteOrderURL, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({
            Content: content,
            Order: order 
          })
      });
      if (!response.ok) {
          throw new Error();
      }
      // Re-fetch the notes after updating the order
    } catch (error) {
        console.error("Error adding note:", error);
    }
  };


  const onDragStart = (e, item) => {
    e.dataTransfer.setData('dragItemId', item.id);
    e.dataTransfer.effectAllowed = 'move';
  };

  const onDragOver = (e) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = 'move';
  };

  const onDrop = (e, dropItem) => {
    const dragItemId = Number(e.dataTransfer.getData('dragItemId'));

    if (dragItemId === null) return;

    if (dropItem !== undefined) {
      updateNoteOrder(dragItemId, dropItem.order)
    } else {
      deleteNoteOrder(dragItemId)
    }
  };

  function handleChangeInput(e) {
    setInput(e.target.value)
  }

  function handleClickTopBtn() {
    addNote(input, 1)
    setInput('')
  }

  function handleClickBottomBtn() {
    addNote(input, items.length + 1)
    setInput('')
  }

  return (
    <div>
      { isAuthenticated &&
        <div className="drag-drop-area">
          <div className='drag-drop-input-area'>
            <input className='drag-drop-input-field' type='text' onChange={e => handleChangeInput(e)} value={input}/>
            <button className='drag-drop-top-btn drag-drop-btn' onClick={handleClickTopBtn}>Top</button>
            <button className='drag-drop-bottom-btn drag-drop-btn'onClick={handleClickBottomBtn}>Bottom</button>
          </div>
          <div className="drag-drop-list">
            {items.map((item, index) => (
              <div
                key={index}
                className="drag-drop-item"
                draggable
                onDragStart={(e) => onDragStart(e, item)}
                onDragOver={onDragOver}
                onDrop={(e) => onDrop(e, item)}
              >
                {item.content}
              </div>
            ))}
          </div>
          <div
            className="drag-drop-remove-area"
            onDragOver={onDragOver}
            onDrop={(e) => onDrop(e)}
          />
        </div>
      }
    </div>
  );
};
