import React, { useState } from 'react';
import './App.css'
import Sidebar from './components/SideBar.js';
import DragDropList from './components/DragDropList.js';
import { AuthProvider } from './components/AuthContext';
import AuthPopUp from './components/AuthPopUp.js';
import SessionExpiredPopUp from './components/SessionExpiredPopUp.js';

function App() {
  const [authPopUpType, setAuthPopUpType] = useState(null);
  const [displaySessionExpiredPopUp, setDisplaySessionExpiredPopUp] = useState(false);

    const showAuthPopUp = (type) => {
        setAuthPopUpType(type);
    };

    const closeAuthPopUp = () => {
        setAuthPopUpType(null);
    };

    const showSessionExpiredPopUp = () => {
        setDisplaySessionExpiredPopUp(true);
    };

    const closeSessionExpiredPopUp = () => {
        setDisplaySessionExpiredPopUp(false);
    };

    return (
        <AuthProvider>
            <div className="App">
              <h1 className='app-name'>Flexi Notes</h1>
              <Sidebar onShowPopup={showAuthPopUp} />
              { 
                authPopUpType 
                && 
                <AuthPopUp type={authPopUpType} onClose={closeAuthPopUp} />
              }
              {
                displaySessionExpiredPopUp
                &&
                <SessionExpiredPopUp onClose={closeSessionExpiredPopUp} />
              }
              <DragDropList onShowSessionExpiredPopUp={showSessionExpiredPopUp} />
            </div>
        </AuthProvider>
    );
}

export default App;
