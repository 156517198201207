// AuthContext.js
import React, { createContext, useState, useEffect } from 'react';

const apiUrl = process.env.REACT_APP_API_BASE_URL;
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [token, setToken] = useState(null);
    const [tokenExpiry, setTokenExpiry] = useState(null);

    useEffect(() => {
        // Check if token is present and valid
        const storedToken = localStorage.getItem('token');
        const storedExpiry = localStorage.getItem('tokenExpiry');

        if (storedToken && new Date(storedExpiry) > new Date()) {
            setToken(storedToken);
            setTokenExpiry(storedExpiry);
            setIsAuthenticated(true);
        }
        else {
            logout()
        }
    }, []);

    const isTokenValid = () => {
        if (new Date() > new Date(tokenExpiry)) {
            logout();
            return false;
        }
        return true;
    }

    const loginURL = `${apiUrl}/api/auth/login`
    const login = async ({ email , password }) => {
        const response = await fetch(loginURL, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email, password }),
        });
        if (!response.ok) {
            return
        }

        const data = await response.json();
        if (data.token) {
            setToken(data.token);
            setTokenExpiry(data.expiration);
            
            localStorage.setItem('token', data.token);
            localStorage.setItem('tokenExpiry', data.expiration);

            setIsAuthenticated(true);
        }
    };

    const registerURL = `${apiUrl}/api/auth/register`
    const register = async ({ email, password }) => {
        const response = await fetch(registerURL, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email, password }),
        });

        if (response.ok) {
            return true
        }

        return false
    };

    const logout = () => {
        setToken(null);
        setTokenExpiry(null);
        
        localStorage.removeItem('token');
        localStorage.removeItem('tokenExpiry');

        setIsAuthenticated(false);
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, login, register, logout, isTokenValid, token }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
