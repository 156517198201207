import React, { useState, useContext, useEffect } from 'react';
import AuthContext from './AuthContext';

const AuthPopUp = ({onClose, type}) => {
    const { login, register, isAuthenticated } = useContext(AuthContext);
    const [formData, setFormData] = useState({ email: '', password: '' });
    const [authAttempted, setAuthAttempted] = useState(false)
    const [registrationAttempted, setRegistrationAttempted] = useState(false)
    const [isRegistered, setIsRegistered] = useState(false)

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        if (isAuthenticated){
            onClose()
            setAuthAttempted(false)
        }
    }, [isAuthenticated])

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (type === 'login') {
            await login(formData)
            setAuthAttempted(true)
        } else {
            setIsRegistered(await register(formData))
            setRegistrationAttempted(true)
        }
    };

    return (
        <div className="popup">
            <div className="popup-content">
                <h2>{type === 'login' ? 'Login' : 'Register'}</h2>
                <form onSubmit={handleSubmit}>
                    <input
                        type="text"
                        name="email"
                        placeholder="Email"
                        onChange={handleChange}
                        value={formData.email}
                        required
                    />
                    <input
                        type="password"
                        name="password"
                        placeholder="Password"
                        onChange={handleChange}
                        value={formData.password}
                        required
                    />
                    <button type="submit">{type === 'login' ? 'Login' : 'Register'}</button>
                    <button type="button" onClick={onClose}>Close</button>
                    <div>{authAttempted && !isAuthenticated && <p>{"Failed. Please check the credentials provided and try again."}</p>}</div>
                    <div>{registrationAttempted && !isRegistered && <p>{"Failed. Please check the credentials provided and try again."}</p>}</div>
                    <div>{registrationAttempted && isRegistered && <p>{"Registration successful. Please log in."}</p>}</div>
                </form>
            </div>
        </div>
    );
};

export default AuthPopUp;
