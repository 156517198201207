import React, { useContext, useState } from 'react';
import AuthContext from './AuthContext';

const Sidebar = (props) => {
    const { isAuthenticated, logout } = useContext(AuthContext);
    // State to control sidebar expansion
    const [isExpanded, setIsExpanded] = useState(false);

    // Toggle the sidebar's state
    const toggleSidebar = () => {
        setIsExpanded(!isExpanded);
    };
    return (
      <div className={`sidebar ${isExpanded ? 'expanded' : 'retracted'}`}>
        <button className="toggle-button" onClick={toggleSidebar}>
            {isExpanded ? '←' : '→'}
        </button>
        <div className="content">
          { 
            isExpanded 
            && 
            <div>
              {!isAuthenticated &&
                <div>
                  <button onClick={() => props.onShowPopup('login')}>Login</button>
                  <button onClick={() => props.onShowPopup('register')}>Register</button>
                </div>
              }
              {isAuthenticated && <button onClick={logout}>Logout</button>}
            </div>
          }
        </div>
      </div>
    );
};

export default Sidebar;
