
const SessionExpiredPopUp = ({ onClose }) => {
    return (
        <div className="popup">
            <div className="popup-content">
                <p>Your session has expired. Please log in again.</p>
                <button type="button" onClick={onClose}>Close</button>
            </div>
        </div>
    );
};

export default SessionExpiredPopUp;